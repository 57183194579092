<template>
  <v-container class="profile-container">
    <v-card class="profile-card">
      <v-card-title class="profile-header">
        <v-avatar size="64" class="mr-4">
          <v-icon large>mdi-account</v-icon>
        </v-avatar>
        <div>
          <h2>
            {{ user.firstName || "Not submitted by user" }}
            {{ user.lastName || "" }}
          </h2>
          <h4>{{ user.email || "Not submitted by user" }}</h4>
        </div>
      </v-card-title>
      <v-card-text>

        <div class="d-flex justify-end mt-5">
          <v-btn dark color="red" @click="blockUser">Block User</v-btn>
        </div>

        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Phone Number</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.phoneNumber || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Role</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.role || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Points</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.points !== undefined
                    ? user.points
                    : "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Referral Code</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.referralCode || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Status</v-list-item-title>
                <v-list-item-subtitle>{{
                  statusMapper(user.status) || "No status details."
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Country</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.country || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Gender</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.gender || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Family Status</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.familyStatus || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Occupation</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.occupation || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Child Age Group</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.childAgeGroup || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Race</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.race || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>State</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.state || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>Bank Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Account Name</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.bankDetails?.accountName || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Account Number</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.bankDetails?.accountNumber || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>IC</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.bankDetails?.ic || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Bank Name</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.bankDetails?.bankName || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>Paypal</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Paypal Info</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.paypalEmail || "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>Subscription</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Newsletter Subscribed</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.newsletterSubscribed ? "Yes" : "Not submitted by user"
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>Other Information</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Joined</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.createdAt
                    ? $moment(user.createdAt).format("DD-MMM-YYYY")
                    : "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Updated Info At</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.updatedAt
                    ? $moment(user.updatedAt).format("DD-MMM-YYYY")
                    : "No Info"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RestResource from "../../..//services/dataServiceMobileV2.js";
const service = new RestResource();

export default {
  data() {
    return {
      user: [],
    };
  },

  mounted() {
    this.loadUserProfile();
  },

  methods: {
    async loadUserProfile() {
      this.$setLoader();
      let res = await service.getUserById({
        userId: this.$route.params.userId,
      });
      this.user = res.data.data;
      this.$disableLoader();
    },
    statusMapper(text) {
      switch (text) {
        case 'P':
          return 'Pending OTP Verification';
        case 'A':
          return 'Active';
        case 'D':
          return 'Deactivated';
        case 'B':
          return 'Blocked';
        default:
          return 'Possible Old User';
      }
    },
    async blockUser() {
      this.$setLoader();
      try {
        await service.updateUserStatus({
          userId: this.$route.params.userId,
          status: 'B',
        });
      } catch (error) {
        console.error('Error blocking user:', error);
        alert(error.message || 'An unexpected error occurred.');
      } finally {
        this.$disableLoader();
      }
    }
  },
};
</script>

<style scoped>
h2 {
  margin-bottom: 0;
}

h4 {
  margin-top: 0;
  color: gray;
}

.profile-container {
  background-color: #f5f5f5;
  /* Light grey background */
  padding: 20px;
  border-radius: 8px;
}

.profile-card {
  background-color: #ffffff;
  /* White background for the card */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-header {
  background-color: #3f51b5;
  /* Primary color background */
  color: white;
  padding: 20px;
  border-radius: 8px 8px 0 0;
}

.profile-header h2,
.profile-header h4 {
  margin: 0;
}

.profile-header h4 {
  color: #e0e0e0;
}
</style>
